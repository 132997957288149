.mt-10 {
    margin-top: 10px !important;
}


.table-operation-edit {
    text-decoration: none !important;
    color: #1677ff !important;
}


.table-operation-delete {
    text-decoration: none !important;
    color: rgb(132, 3, 3) !important;
}


.project-card {
    width: 100%;
    border: solid 1px #EAEAEA;
    border-radius: 5px;
    min-height: 230px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.project-card>img {
    /* width: 200px; */
    background-color: red;
}

.project-card-body {
    padding: 20px 30px;
    width: 100%;
    position: relative;
}

.project-operation {
    position: absolute;
    right: 0px;
}