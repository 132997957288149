.demo-logo-vertical {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    flex-direction: column;
}

.demo-logo-vertical>img {
    height: 120px;
}

.demo-logo-vertical>h5 {
    color: white;
}

.ant-layout-sider {
    max-width: none !important;
    width: 40% !important;
}

.sidebar-bottom {
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.sidebar-bottom>.user-email {
    background-color: #63dbd1;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 5px;
    border-radius: 5px;
}

.sidebar-bottom>.user-email>span>svg {
    margin-right: 7px;
}


.sidebar-bottom>button {
    border: none;
    background-color: rgb(97, 15, 15);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.sidebar-bottom>button {
    margin: 5px;
}

/* navlinks */
.ant-menu-item {
    padding-inline: 0 !important;
}

.sidebar-menu-item>.ant-menu-title-content {
    display: flex !important;
}

.secondHeader-item {
    width: 100%;
    height: 100%;
    text-decoration: none !important;
    padding-left: 15px !important;
}

.secondHeader-item-active {
    background-color: #1677ff;
}