.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login-card{
    width: 400px;
}

.login-card>.ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.login-card>.ant-card-body>form {
    width: 80%;
}


.login-cover {
    height: 100px !important;
    width: 100px !important;
    margin-bottom: 20px;
}